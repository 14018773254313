/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("./src/assets/styles/sass/styles-lukekalender.scss")


exports.onInitialClientRender = () => {
    // console.log(window.$('.owl-carousel').length)

    // var s = document.createElement("script");
    // s.type = "text/javascript";
    // s.src = "/assets/scripts/scripts.js";
    // window.$("head").append(s);

    // $('.js-faq-toggle').on('click', function (e) {
    //     e.preventDefault();
    //     var $controls = $(this).attr('aria-controls');
    //     var $expanded = $(this).attr('aria-expanded');
    //     var $dialog = $('#' + $controls);
    
    //     $dialog.toggleClass('is-open');
    
    //     if ($expanded == 'true') {
    //       $(this).attr('aria-expanded', 'false');
    //     } else {
    //       $(this).attr('aria-expanded', 'true');
    //       $dialog.focus();
    //     }
    // });

}